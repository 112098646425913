import { helpers } from "vuelidate/lib/validators";
import libPhone from "google-libphonenumber";

export function accepted(value) {
  return !!value;
}

export function nipFormat(value) {
  return !!value && value.length === 10;
}

export function optionalPhoneNumber(value) {
  if (!value) {
    return true;
  }
  if (!helpers.req(value.phonePrefix && value.phonePrefix.phonePrefix) && !helpers.req(value.phoneNumber)) {
    return true;
  } else {
    const phoneUtil = libPhone.PhoneNumberUtil.getInstance();
    const phoneCountry = value.phonePrefix;
    if (phoneCountry) {
      try {
        const myPhoneNumber = phoneUtil.parse(`${phoneCountry.phonePrefix}${value.phoneNumber}`, phoneCountry.region);
        return phoneUtil.isValidNumberForRegion(myPhoneNumber, phoneCountry.region);
      } catch (err) {
        return false;
      }
    }
    return false;
  }
}

export function requiredPhoneNumber(value) {
  if (value) {
    const phoneUtil = libPhone.PhoneNumberUtil.getInstance();
    const phoneCountry = value.phonePrefix;
    if (phoneCountry) {
      try {
        const myPhoneNumber = phoneUtil.parse(`${phoneCountry.phonePrefix}${value.phoneNumber}`, phoneCountry.region);
        return phoneUtil.isValidNumberForRegion(myPhoneNumber, phoneCountry.region);
      } catch (err) {
        return false;
      }
    }
  }
  return false;
}

export function optionalUrlValidator(value) {
  if (value) {
    return value.startsWith("http");
  } else {
    return true;
  }
}

export function hexColor(value) {
  if (value) {
    return value.startsWith("#") && /^#[0-9A-F]{6}$/i.test(value);
  } else {
    return true;
  }
}
