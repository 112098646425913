<template>
  <attachment>
    <download-icon class="download-icon" slot="right"></download-icon>
    <a :href="targetUrl" target="_blank" rel="noopener noreferrer">{{ filename }}</a>
  </attachment>
</template>

<script>
import DownloadIcon from "@/assets/icons/download.svg";
import Attachment from "@/web/components/shared/Attachment";

export default {
  name: "FileAttachment",

  components: {
    DownloadIcon,
    Attachment,
  },

  props: ["file"],

  computed: {
    filename() {
      return this.file && this.file.name;
    },

    targetUrl() {
      return this.file && this.file.url;
    },
  },
};
</script>

<style lang="scss" scoped>
.download-icon {
  fill: #4f4f4f;
  fill: var(--primary-color);
  display: block;
  width: 24px;
  height: 24px;
}
</style>
