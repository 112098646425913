<template>
  <paginated-content :items="agendaSessions" :perPage="1">
    <template v-slot="{ displayItems }">
      <div class="columns">
        <div v-for="(agendaSession, index) in displayItems" :key="index" class="column">
          <event-day class="mb-1" :day="agendaSession.agenda_session_day" v-if="headerDayVisible"></event-day>
          <agenda-session :session="agendaSession"></agenda-session>
        </div>
      </div>
    </template>
  </paginated-content>
</template>

<script>
import PaginatedContent from "@/shared/components/common/pagination/PaginatedContent";
import AgendaSession from "@/web/components/agenda/AgendaSession";
import EventDay from "@/shared/components/common/day/EventDay";

export default {
  name: "AgendaSessionPagerView",

  props: ["agendaSessions", "headerDayVisible"],

  components: {
    EventDay,
    AgendaSession,
    PaginatedContent,
  },
};
</script>

<style lang="scss" scoped>
.columns {
  flex-wrap: wrap;
}

//.button {
//
//  text-transform: capitalize;
//  background-color: var(--dropdown-item-background-color);
//  border-color: var(--dropdown-border-color);
//
//  &.is-active {
//    color: var(--text-color-secondary);
//    background-color: var(--primary-color);
//    border-color: var(--dropdown-border-color);
//  }
//}
</style>
