<template>
  <div class="columns is-gapless is-mobile is-vcentered">
    <div v-if="showArrows" class="column is-narrow mr-1">
      <button type="button" class="button px-1" :class="{ 'no-border': noBorder }" @click="prev" :disabled="previousArrowDisabled">
        <arrow-left class="svg-icon has-stroke-primary"></arrow-left>
      </button>
    </div>

    <div class="column">
      <slot :visibleItems="visibleItems">
        <span v-for="(item, index) in visibleItems" :key="index">{{ item }}</span>
      </slot>
    </div>

    <div v-if="showArrows" class="column is-narrow ml-1">
      <button type="button" class="button px-1" :class="{ 'no-border': noBorder }" @click="next" :disabled="nextArrowDisabled">
        <arrow-right class="svg-icon has-stroke-primary"></arrow-right>
      </button>
    </div>
  </div>
</template>

<script>
import ArrowRight from "@/assets/arrow-right.svg";
import ArrowLeft from "@/assets/arrow-left.svg";

export default {
  name: "Scrollable",

  components: {
    ArrowRight,
    ArrowLeft,
  },

  props: ["items", "limit", "noBorder"],

  data() {
    return {
      index: 0,
    };
  },

  methods: {
    next() {
      const nextIndex = this.index + this.limit;
      this.index = Math.min(this.maxItems, nextIndex);
    },

    prev() {
      const prevIndex = this.index - this.limit;
      this.index = Math.max(0, prevIndex);
    },

    setIndex(index) {
      for (let newIndex = 0; newIndex < this.items.length; newIndex = newIndex + this.limit) {
        if (index >= newIndex && index < newIndex + this.limit) {
          if (newIndex > this.maxItems) {
            this.index = this.maxItems;
          } else {
            this.index = newIndex;
          }
          return;
        }
      }
    },
  },

  computed: {
    maxItems() {
      return Math.max(0, this.items.length - this.limit);
    },

    visibleItems() {
      const from = this.index;
      const to = from + this.limit;
      return this.items.slice(from, to);
    },

    previousArrowDisabled() {
      return this.index === 0;
    },

    nextArrowDisabled() {
      return this.index >= this.maxItems;
    },

    showArrows() {
      const items = [].concat(this.items || []);
      return items.length > this.limit;
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  background-color: var(--global-item-background-color);
  border-color: var(--dropdown-border-color);
  width: 42px;
  height: 42px;

  &.no-border {
    border: 0;
  }

  &[disabled] {
    background-color: var(--global-item-background-color);
    border-color: var(--dropdown-border-color);
  }
}
</style>
