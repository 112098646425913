<template>
  <div class="box selectable is-flex is-flex-direction-column is-justify-content-center is-align-items-center" :class="boxClass">
    <div class="bm-timeslot-start-time">
      {{ formattedStartTime }}
    </div>
    <div class="bm-timeslot-duration">
      {{ formattedDuration }}
    </div>
  </div>
</template>

<script>
import { composeBMTimeSlotTime, composeBMTimeSlotDuration } from "@/shared/utils";

export default {
  name: "BMTimeSlot",

  props: {
    timeSlot: {
      type: Object,
      required: true,
    },

    timezone: {
      type: String,
      required: true,
    },

    selected: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    formattedStartTime() {
      return composeBMTimeSlotTime(this.timeSlot.time_start, this.timezone);
    },

    formattedDuration() {
      return composeBMTimeSlotDuration(this.timeSlot.time_start, this.timeSlot.time_end, this.$i18n);
    },

    boxClass() {
      if (this.selected) {
        return "selected";
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.bm-timeslot-start-time {
  font-size: 16px;
  line-height: 130%;
  font-weight: bolder;
}

.bm-timeslot-duration {
  font-size: 16px;
  line-height: 130%;
  font-weight: bold;
}
</style>
