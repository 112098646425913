<template>
  <div class="ma-tag" :class="{ 'with-cursor-pointer': clickable }" :style="tagBarStyle">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TagContent",

  props: ["color", "clickable"],

  computed: {
    tagBarStyle() {
      return {
        backgroundColor: this.color,
      };
    },
  },
};
</script>
