<template>
  <html id="app" class="widget-container" :theme="rootTheme">
    <body :id="bodyId">
      <widget-return-button v-if="!isMainViewActive && !lpMode"></widget-return-button>
      <div class="is-relative widget-main-content" :class="rootClass">
        <slot></slot>
        <b-modal v-model="errorModalActive" has-modal-card full-screen :can-cancel="true" :trap-focus="true">
          <error-modal v-bind="errorModalProps"></error-modal>
        </b-modal>
      </div>
      <div
        v-if="!isMainViewActive && !lpMode"
        class="navigate-back-button button is-fullwidth button-text is-primary is-filled"
        @click="navigateBackComponentView"
      >
        {{ $t("widgets.back_to_list") }}
      </div>
    </body>
  </html>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ErrorModal from "@/shared/components/modals/ErrorModal";
import ErrorModalMixin from "@/widgets/shared/mixins/ErrorModalMixin";
import WidgetReturnButton from "@/widgets/shared/components/WidgetReturnButton";
import { WidgetViewTypes } from "@/widgets/shared/store/modules/widget-view";

//This component requires "error-modal" module present in namespaced vuex modules
export default {
  name: "WidgetRootLayout",
  mixins: [ErrorModalMixin],
  components: {
    ErrorModal,
    WidgetReturnButton,
  },
  props: {
    rootClass: {
      type: Object,
      required: false,
      default: () => {},
    },
    rootTheme: {
      type: String,
      required: false,
    },
    lpMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    newLpMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters("widgetView", ["viewsTree", "activeView", "scrollTopPos", "lastChangedForComponentId"]),

    componentScrollPos() {
      return this.scrollTopPos(this.$root.componentId);
    },

    currentView() {
      return this.activeView(this.$root.componentId);
    },

    isMainViewActive() {
      return this.currentView.view === WidgetViewTypes.MAIN;
    },

    bodyId() {
      return this.getBodyIdFor(this.$root.componentId || 0);
    },
  },

  watch: {
    viewsTree() {
      if (this.currentView && this.currentView.view === WidgetViewTypes.MAIN && !!this.componentScrollPos) {
        this.scrollTo(this.componentScrollPos);
      } else {
        this.scrollTo(0);
      }
    },
  },

  methods: {
    ...mapMutations("widgetView", ["navigateBack"]),

    getBodyIdFor(componentId) {
      return `id-${componentId || 0}`;
    },

    navigateBackComponentView() {
      this.navigateBack(this.$root.componentId);
    },

    scrollTo(topPos) {
      const subjectBodyId = this.getBodyIdFor(this.lastChangedForComponentId);
      const widget = this.$el.querySelector(`body[id=${subjectBodyId}]`);
      const scrollContainer = this.newLpMode ? document.querySelector(".event-main") : window;
      if (widget) {
        if (topPos) {
          setTimeout(() => {
            scrollContainer.scrollTo({
              top: topPos,
            });
          });
        } else {
          const rect = widget.getBoundingClientRect();
          const absoluteElementTop = rect.top + (scrollContainer === window ? window.pageYOffset : scrollContainer.scrollTop);
          const fixedElementTop = absoluteElementTop - 200;

          scrollContainer.scrollTo({
            top: fixedElementTop,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-container {
  overflow-y: hidden !important;
}

.widget-main-content {
  margin-top: 10px;
}

.navigate-back-button {
  margin-top: 20px;
}
</style>
