<template>
  <div class="social-links">
    <template v-for="(social, index) in links">
      <a
        :href="social.target"
        :key="index"
        :class="social.type"
        class="social-link"
        rel="noopener noreferrer"
        :style="{
          padding: `${padding}px`,
          width: `${diameter}px`,
          height: `${diameter}px`,
        }"
        target="_blank"
      >
        <social-facebook-icon v-if="social.type === 'facebook'"></social-facebook-icon>
        <social-linkedin-icon v-if="social.type === 'linkedin'"></social-linkedin-icon>
        <social-x-icon v-if="social.type === 'x' || social.type === 'twitter'"></social-x-icon>
        <social-instagram-icon v-if="social.type === 'instagram'"></social-instagram-icon>
        <social-youtube-icon v-if="social.type === 'youtube'"></social-youtube-icon>
      </a>
    </template>
  </div>
</template>

<script>
import SocialFacebookIcon from "@/assets/contact/social-facebook-icon.svg";
import SocialLinkedinIcon from "@/assets/contact/social-linkedin-icon.svg";
import SocialXIcon from "@/assets/contact/social-x-icon.svg";
import SocialInstagramIcon from "@/assets/contact/social-instagram-icon.svg";
import SocialYoutubeIcon from "@/assets/contact/social-youtube-icon.svg";

/** @deprecated @see SocialLinkList */
export default {
  name: "SocialLinks",

  props: ["links", "size"],

  components: {
    SocialFacebookIcon,
    SocialLinkedinIcon,
    SocialXIcon,
    SocialInstagramIcon,
    SocialYoutubeIcon,
  },
  computed: {
    diameter() {
      return this.size || 48;
    },

    padding() {
      return (this.diameter - 24) / 2.0;
    },
  },
};
</script>

<style lang="scss" scoped>
.social-links {
  display: flex;
  flex-wrap: wrap;
  min-width: 0;
}

.social-link {
  background: #e8e8e8;
  border-radius: 100%;
  display: block;

  &:not(:last-child) {
    margin-right: 20px;
  }

  svg {
    display: block;
    width: 24px !important;
    height: 24px !important;
  }

  &.facebook {
    background: #395ca9;
  }

  &.linkedin {
    background: #006cac;
  }

  &.twitter,
  &.x {
    background: #000000;
  }

  &.instagram {
    background: #fa7e1e;
    background: linear-gradient(98.31deg, #feda75 6.52%, #fa7e1e 29.27%, #d62976 52.93%, #962fbf 72.95%, #4f5bd5 93.35%);
  }

  &.youtube {
    background: #ff0000;
  }
}
</style>
