export function getEventsFromICalString(iCalString, event, iCalParser) {
  const startDate = new Date(event.start_date);
  const endDate = new Date(event.end_date);
  const iCalComponent = new iCalParser.Component(iCalParser.parse(iCalString));
  const singleEvents = getSingleEvents(iCalComponent, iCalParser);
  const recurringEvents = getRecurringEventsFromDateRange(iCalComponent, iCalParser, startDate, endDate);
  return [...singleEvents, ...recurringEvents];
}

function getSingleEvents(iCalComponent, iCalParser, startTimestamp, endTimestamp) {
  const vevents = iCalComponent
    .getAllSubcomponents("vevent")
    .filter(c => !c.hasProperty("recurrence-id"))
    .filter(c => !c.hasProperty("rrule"))
    .map(c => new iCalParser.Event(c, { strictExceptions: true }))
    .map(vevent => {
      return { startDate: vevent.startDate.toJSDate().toISOString(), endDate: vevent.endDate.toJSDate().toISOString() };
    });
  // console.log(`singleEvents`);
  // console.log(vevents);
  return vevents;
}

function getRecurringEventsFromDateRange(iCalComponent, iCalParser, startDate, endDate) {
  const rangeStart = iCalParser.Time.fromJSDate(startDate);
  const rangeEnd = iCalParser.Time.fromJSDate(endDate);
  const events = [];
  const vevents = iCalComponent
    .getAllSubcomponents("vevent")
    .filter(c => !c.hasProperty("recurrence-id"))
    .filter(c => c.hasProperty("rrule"))
    .map(c => new iCalParser.Event(c, { strictExceptions: true }));

  vevents.forEach(vevent => {
    const dtstart = vevent.dtstart;
    const iterator = vevent.iterator(dtstart);
    for (var next = iterator.next(); next; next = iterator.next()) {
      if (next.compare(rangeStart) < 0) {
        continue;
      }
      if (next.compare(rangeEnd) > 0) {
        return;
      }
      const details = vevent.getOccurrenceDetails(next);
      events.push({
        startDate: details.startDate.toJSDate().toISOString(),
        endDate: details.endDate.toJSDate().toISOString(),
      });
    }
  });
  // console.log(`recurEvents`);
  // console.log(events);
  return events;
}
