<template>
  <div class="field">
    <label class="label has-text-primary">{{ label }}</label>
    <div class="control">
      <input class="input is-simple" type="text" :placeholder="label" v-model="value" />
    </div>
    <template v-if="errorsVisible">
      <p v-if="requiredError" class="help is-danger">{{ $t("common.field_required_error") }}</p>
      <p v-if="nipError" class="help is-danger">{{ $t("common.field_error_nip") }}</p>
    </template>
  </div>
</template>

<script>
import FormBuilderConstants from "@/web/views/dev/formbuilder/form-builder-constants";

export default {
  name: "RegisterNipInput",

  props: {
    fieldData: {
      type: Object,
      required: true,
    },
    errorsVisible: {
      type: Boolean,
      required: true,
    },
    validation: {
      type: Object,
      required: true,
    },
    translationsNamespace: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      value: "",
    };
  },

  computed: {
    fieldId() {
      return this.fieldData.id;
    },

    requiredError() {
      const validationKey = FormBuilderConstants.VALIDATIONS.REQUIRED;
      return validationKey in this.validation && !this.validation[validationKey];
    },

    nipError() {
      const validationKey = FormBuilderConstants.VALIDATIONS.NIP_FORMAT;
      return validationKey in this.validation && !this.validation[validationKey];
    },

    label() {
      return this.$t(`${this.translationsNamespace}.${this.fieldData.id}_label`);
    },
  },

  watch: {
    value(newValue) {
      this.$emit("input", newValue.toLowerCase());
    },
  },
};
</script>

<style scoped></style>
