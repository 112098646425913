import { getTimestampRangeFromEventDay, isTimestampBetweenRange, areTimestampRangesColliding } from "@/shared/utils";
import dayjs from "@/shared/utils/day";

export function transformUnavailabilityToAvailability(unavailability, eventDays) {
  if (unavailability.length === 0 || eventDays.length === 0) {
    return [];
  }
  const eventStartTimestamp = getTimestampRangeFromEventDay(eventDays[0]).startTimestamp;
  const eventEndTimestamp = getTimestampRangeFromEventDay(eventDays[eventDays.length - 1]).endTimestamp;

  let availability = [{ startTimestamp: eventStartTimestamp, endTimestamp: eventEndTimestamp }];
  unavailability.forEach(unavailable => {
    availability = availability.reduce((acc, available) => {
      const unavailableRange = {
        startTimestamp: new Date(unavailable.unavailability_from).getTime(),
        endTimestamp: new Date(unavailable.unavailability_to).getTime(),
      };
      if (
        areTimestampRangesColliding(
          available.startTimestamp,
          available.endTimestamp,
          unavailableRange.startTimestamp,
          unavailableRange.endTimestamp
        )
      ) {
        if (unavailableRange.startTimestamp > available.startTimestamp && unavailableRange.endTimestamp < available.endTimestamp) {
          return [
            ...acc,
            {
              startTimestamp: available.startTimestamp,
              endTimestamp: unavailableRange.startTimestamp,
            },
            { startTimestamp: unavailableRange.endTimestamp, endTimestamp: available.endTimestamp },
          ];
        } else if (
          unavailableRange.startTimestamp <= available.startTimestamp &&
          isTimestampBetweenRange(unavailableRange.endTimestamp, available.startTimestamp, available.endTimestamp)
        ) {
          return [...acc, { startTimestamp: unavailableRange.endTimestamp, endTimestamp: available.endTimestamp }];
        } else if (
          unavailableRange.endTimestamp >= available.endTimestamp &&
          isTimestampBetweenRange(unavailableRange.startTimestamp, available.startTimestamp, available.endTimestamp)
        ) {
          return [...acc, { startTimestamp: available.startTimestamp, endTimestamp: unavailableRange.startTimestamp }];
        }
      } else {
        return [...acc, available];
      }
    }, []);
  });
  return availability;
}

export function transformAvailabilityToUnavailability(availability, eventDays, timezone) {
  const startTimestamp = getTimestampRangeFromEventDay(eventDays[0]).startTimestamp;
  const endTimestamp = getTimestampRangeFromEventDay(eventDays[eventDays.length - 1]).endTimestamp;

  let unavailability = [{ startTimestamp, endTimestamp }];
  availability.forEach(available => {
    unavailability = unavailability.reduce((acc, unavailable) => {
      if (
        areTimestampRangesColliding(unavailable.startTimestamp, unavailable.endTimestamp, available.startTimestamp, available.endTimestamp)
      ) {
        if (available.startTimestamp > unavailable.startTimestamp && available.endTimestamp < unavailable.endTimestamp) {
          acc.push({ startTimestamp: unavailable.startTimestamp, endTimestamp: available.startTimestamp });
          acc.push({ startTimestamp: available.endTimestamp, endTimestamp: unavailable.endTimestamp });
        } else if (
          available.startTimestamp <= unavailable.startTimestamp &&
          isTimestampBetweenRange(available.endTimestamp, unavailable.startTimestamp, unavailable.endTimestamp)
        ) {
          acc.push({ startTimestamp: available.endTimestamp, endTimestamp: unavailable.endTimestamp });
        } else if (
          available.endTimestamp >= unavailable.endTimestamp &&
          isTimestampBetweenRange(available.startTimestamp, unavailable.startTimestamp, unavailable.endTimestamp)
        ) {
          acc.push({ startTimestamp: unavailable.startTimestamp, endTimestamp: available.startTimestamp });
        }
      } else {
        acc.push(unavailable);
      }
      return acc;
    }, []);
  });
  return unavailability.map(unavailable => ({
    from: new Date(unavailable.startTimestamp).toISOString(),
    to: new Date(unavailable.endTimestamp).toISOString(),
  }));
}

export function mergeCollidingAvailabilities(availabilities) {
  const stack = [];

  const sortedAvailabilities = availabilities.sort((a, b) => a.startTimestamp - b.startTimestamp);
  sortedAvailabilities.forEach(currentAvailability => {
    if (stack.length === 0) {
      stack.push(currentAvailability);
    } else {
      const previousAvailability = stack.pop();
      if (previousAvailability.endTimestamp >= currentAvailability.startTimestamp) {
        /* If current interval's start time is less than end time of
          previous interval, find max of end times of two intervals
          and push new interval on to stack. */
        const endTimestamp = Math.max(previousAvailability.endTimestamp, currentAvailability.endTimestamp);
        stack.push({ ...previousAvailability, endTimestamp: endTimestamp });
      } else {
        stack.push(previousAvailability);
        stack.push(currentAvailability);
      }
    }
  });
  return stack;
}

export function transformAvailabilityToGraphRanges(availabilities, day, graphWidth, timezone) {
  const dayRange = getTimestampRangeFromEventDay(day);
  return availabilities
    .map(available => ({
      ...available,
      startMoment: dayjs.tz(available.startTimestamp, timezone),
      endMoment: dayjs.tz(available.endTimestamp, timezone),
    }))
    .map(({ startMoment, endMoment, endTimestamp }) => {
      const dayInMinutes = 24 * 60;
      const startMinutes = startMoment.hour() * 60 + startMoment.minute();
      let endMinutes = endMoment.hour() * 60 + endMoment.minute();
      if (endTimestamp === dayRange.endTimestamp) {
        endMinutes = dayInMinutes;
      }
      const startPoint = (startMinutes / dayInMinutes) * graphWidth;
      const endPoint = (endMinutes / dayInMinutes) * graphWidth;
      return {
        startPointX: startPoint,
        width: startPoint - endPoint,
      };
    });
}

export function removeAvailabilityFromCurrentData(currentAvailability, availabilityToRemove) {
  return currentAvailability.reduce((acc, available) => {
    if (
      areTimestampRangesColliding(
        available.startTimestamp,
        available.endTimestamp,
        availabilityToRemove.startTimestamp,
        availabilityToRemove.endTimestamp
      )
    ) {
      if (available.startTimestamp < availabilityToRemove.startTimestamp && available.endTimestamp > availabilityToRemove.endTimestamp) {
        acc.push({ startTimestamp: available.startTimestamp, endTimestamp: availabilityToRemove.startTimestamp });
        acc.push({ startTimestamp: availabilityToRemove.endTimestamp, endTimestamp: available.endTimestamp });
      } else if (
        availabilityToRemove.startTimestamp < available.startTimestamp &&
        isTimestampBetweenRange(availabilityToRemove.endTimestamp, available.startTimestamp, available.endTimestamp)
      ) {
        acc.push({ startTimestamp: availabilityToRemove.endTimestamp, endTimestamp: available.endTimestamp });
      } else if (
        availabilityToRemove.endTimestamp > available.endTimestamp &&
        isTimestampBetweenRange(availabilityToRemove.startTimestamp, available.startTimestamp, available.endTimestamp)
      ) {
        acc.push({ startTimestamp: available.startTimestamp, endTimestamp: availabilityToRemove.startTimestamp });
      }
      return acc;
    } else {
      return [...acc, available];
    }
  }, []);
}

// Enable backward compatibility
export default {
  transformUnavailabilityToAvailability,
  transformAvailabilityToUnavailability,
  mergeCollidingAvailabilities,
  transformAvailabilityToGraphRanges,
  removeAvailabilityFromCurrentData,
};
