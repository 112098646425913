<template>
  <div v-if="countries">
    <div class="country-list is-flex is-flex-direction-row">
      <div v-for="{ name, code } in displayCountries" v-bind:key="code">
        <div class="country-name is-flex is-flex-direction-row">
          <div class="mr-1 has-flag">
            <CountryFlag :code="code" />
          </div>
          <div class="mr-2">
            {{ name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { countries } from "countries-list";
import CountryFlag from "@/web/components/shared/CountryFlag";

export default {
  name: "CountriesList",

  components: {
    CountryFlag,
  },

  props: ["countries"],

  computed: {
    displayCountries() {
      const countryCodes = this.countries.toUpperCase().split(",");
      return countryCodes.map(code => ({
        name: countries[code].name,
        code: code,
      }));
    },
  },
};
</script>

<style scoped>
.country-list {
  flex-wrap: wrap;
  min-width: 0;
}

.country-name {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  color: var(--text-color-primary);
}

.has-flag img {
  display: block;
  width: 18px;
  height: auto;
}
</style>
