<template>
  <div v-if="collapsed" class="field">
    <b-button type="is-light" expanded @click="collapsed = false">
      {{ $t(buttonKey) }}
    </b-button>
  </div>
  <div v-else class="field">
    <span v-if="hasHtml" v-html="$t(labelKey)"></span>
    <i18n v-else :path="labelKey" tag="span" class="consents is-size-7">
      <template v-if="hasSubstrings">
        <a target="_blank" v-for="substringLinkPair in substringLinkMap" :href="substringLinkPair.link" :key="substringLinkPair.substring">
          {{ substringLinkPair.substring }}
        </a>
      </template>
    </i18n>
  </div>
</template>

<script>
import FormBuilderConstants from "@/web/views/dev/formbuilder/form-builder-constants";

export default {
  name: "RegisterInformationInput",

  props: {
    fieldData: {
      type: Object,
      required: true,
    },
    errorsVisible: {
      type: Boolean,
      required: true,
    },
    validation: {
      type: Object,
      required: true,
    },
    translationsNamespace: {
      type: String,
      required: true,
    },
  },

  data() {
    // Fetch initial collapsed value
    // Any other value than 'true' resolves to 'false'
    const isCollapsed = this.fieldData[FormBuilderConstants.FIELD_PROPERTIES.COLLAPSED] === true;

    return {
      value: "",
      collapsed: isCollapsed,
    };
  },

  computed: {
    fieldId() {
      return this.fieldData[FormBuilderConstants.FIELD_PROPERTIES.ID];
    },

    hasHtml() {
      return this.fieldData[FormBuilderConstants.FIELD_PROPERTIES.HAS_HTML];
    },

    labelKey() {
      return `${this.translationsNamespace}.${this.fieldId}_label`;
    },

    buttonKey() {
      return `${this.translationsNamespace}.${this.fieldId}_button`;
    },

    substringsKey() {
      return `${this.translationsNamespace}.${this.fieldId}_collapsed_substrings`;
    },

    linksKey() {
      return `${this.translationsNamespace}.${this.fieldId}_collapsed_links`;
    },

    substringLinkMap() {
      const links = Object.values(this.$t(this.linksKey));
      const substrings = Object.values(this.$t(this.substringsKey));

      if (substrings && substrings.length) {
        return substrings.map((substring, index) => {
          return {
            substring: substring,
            link: links[index],
          };
        });
      } else {
        return [];
      }
    },

    hasSubstrings() {
      return this.substringLinkMap.length > 0;
    },
  },

  watch: {
    value(newValue) {
      this.$emit("input", newValue);
    },
  },
};
</script>

<style scoped></style>
