<template>
  <div>
    <!-- Paginated content -->
    <div class="mb-3 paginated" :style="paginatedStyle" ref="paginatedContent">
      <slot :displayItems="displayItems" :currentPage="current" v-if="displayItems.length"></slot>

      <!-- Empty list placeholder-->
      <template v-else>
        <empty-list-placeholder :style="paginatedStyle" class="has-fullheight"></empty-list-placeholder>
      </template>
    </div>

    <!-- Page navigator -->
    <template v-if="showPagination">
      <b-pagination
        class="unselectable is-marginless"
        :total="total"
        :perPage="perPage"
        :order="'is-centered'"
        v-model="current"
      ></b-pagination>
    </template>
  </div>
</template>

<script>
import EmptyListPlaceholder from "@/shared/components/common/placeholders/EmptyListPlaceholder";
export default {
  name: "PaginatedContent",
  components: { EmptyListPlaceholder },
  props: ["items", "perPage", "parentId"],

  data() {
    return {
      current: 1,
      minHeight: null,
    };
  },

  mounted() {
    this.$nextTick(() => {
      // this.minHeight = this.$refs.paginatedContent.scrollHeight;
    });
  },

  methods: {
    getPageItems(page) {
      const items = this.items || [];
      const to = page * this.perPage;
      const from = to - this.perPage;

      return items.slice(from, to);
    },
  },

  computed: {
    total() {
      return this.items && this.items.length;
    },

    displayItems() {
      return this.getPageItems(this.current);
    },

    showPagination() {
      return this.total > this.perPage;
    },

    paginatedStyle() {
      return {
        minHeight: this.minHeight + "px",
      };
    },
  },

  watch: {
    items: function (newVal, oldVal) {
      this.current = 1;
    },

    current: {
      immediate: false,
      handler: function (newValue) {
        this.$nextTick(() => {
          if (this.parentId) {
            const element = document.getElementById(this.parentId);
            let rect = element.getBoundingClientRect();
            let elemTop = rect.top;
            let elemBottom = rect.bottom;

            // Only completely visible elements return true:
            let isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
            if (!isVisible) {
              element.scrollIntoView({ behavior: "smooth", block: "start" });
            }
          }
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
