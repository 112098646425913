export function getCurrencyMultiplier(currency) {
  const lowerCaseCurrency = currency.toLowerCase();
  if (lowerCaseCurrency === "huf" || lowerCaseCurrency === "jpy") {
    return 0;
  } else {
    return 0.01;
  }
}

export function getDiscountAmountForSingleTicket(discount, ticketPrice) {
  if (discount) {
    if (discount.discount_percentage) {
      //code with percentage discount
      return discount.discount_percentage * 0.01 * ticketPrice;
    } else {
      //code with amount discount
      const discountedAmount = discount.discount_amount;
      return Math.min(ticketPrice, discountedAmount);
    }
  }
  return 0;
}

export function getMarketingDiscountAmountForSingleTicket(marketingDiscount, ticketPrice) {
  if (marketingDiscount) {
    return ticketPrice * marketingDiscount * 0.01;
  }
  return 0;
}

export function getBulkDiscountAmountForSingleTicket(bulkDiscount, ticketPrice) {
  if (bulkDiscount) {
    return Math.min(ticketPrice, bulkDiscount.discount_amount);
  } else {
    return 0;
  }
}

export function getMarketingDiscountAmount(discount, marketingDiscount, ticketPrice, discountedTicketsQuantity, ticketsQuantity) {
  if (marketingDiscount) {
    const marketingDiscountPerTicket = ticketPrice * marketingDiscount * 0.01;
    const discountAmountForTicketsWithCode =
      discountedTicketsQuantity *
      Math.min(ticketPrice - getDiscountAmountForSingleTicket(discount, ticketPrice), marketingDiscountPerTicket);
    const discountAmountForRestOfTickets = (ticketsQuantity - discountedTicketsQuantity) * marketingDiscountPerTicket;
    return discountAmountForTicketsWithCode + discountAmountForRestOfTickets;
  }
  return 0;
}

export function getBulkDiscountAmount(discount, marketingDiscount, bulkDiscount, ticketPrice, discountedTicketsQuantity, ticketsQuantity) {
  if (bulkDiscount) {
    let totalDiscountedTicketsAmount = 0;
    for (let i = 1; i <= ticketsQuantity; i++) {
      const discounted =
        discount && i <= discountedTicketsQuantity ? Math.min(ticketPrice - getDiscountAmountForSingleTicket(discount, ticketPrice)) : 0;
      const discountedMarketing = marketingDiscount ? ticketPrice * marketingDiscount * 0.01 : 0;
      let totalDiscount = bulkDiscount.discount_amount + discounted + discountedMarketing;
      if (totalDiscount > ticketPrice) {
        totalDiscountedTicketsAmount = totalDiscountedTicketsAmount + (ticketPrice - discounted - discountedMarketing);
      } else {
        totalDiscountedTicketsAmount = totalDiscountedTicketsAmount + bulkDiscount.discount_amount;
      }
    }
    return totalDiscountedTicketsAmount;
  }
  return 0;
}
