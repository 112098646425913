<template>
  <div class="widget-return-button" @click="goBack">
    <div class="back-button">
      <back-icon class="back-icon"></back-icon>
    </div>
    <span>{{ $t("widgets.return") }}</span>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import BackIcon from "@/assets/icon_back_arrow.svg";

export default {
  name: "WidgetReturnButton",
  components: {
    BackIcon,
  },

  methods: {
    ...mapMutations("widgetView", ["navigateBack"]),

    goBack() {
      this.navigateBack(this.$root.componentId);
    },
  },
};
</script>
