export function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    let args = arguments;
    let that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

export function throttle(fn, delay) {
  let timeoutID = null;
  return function () {
    if (!timeoutID) {
      timeoutID = setTimeout(function () {
        timeoutID = null;
      }, delay);
      fn.apply(this, arguments);
    }
  };
}
