import { getInterceptor, storeInterceptor } from "@/widgets/shared/utils/axios-instance";
import { detectBrowserLanguage } from "@/widgets/shared/utils";

const createBearer = token => `Bearer ${token}`;

function installInterceptors(axios, store) {
  const currentInterceptor = getInterceptor();
  if (currentInterceptor >= 0) {
    axios.interceptors.request.eject(currentInterceptor);
  }
  const interceptor = axios.interceptors.request.use(config => {
    const { token, accessCode, locale, appId } = store.state;
    if (appId) {
      config["headers"]["AppId"] = appId;
    }
    if (token) {
      config["headers"]["Authorization"] = createBearer(token);
    }
    if (accessCode) {
      config["headers"]["AccessCode"] = accessCode;
    }
    config["headers"]["Accept-Language"] = locale || detectBrowserLanguage();

    return config;
  });
  storeInterceptor(interceptor);
}

export function install(axios) {
  return function (store) {
    installInterceptors(axios, store);
  };
}

export default {
  install,
};
