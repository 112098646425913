<template>
  <b-dropdown
    :expanded="true"
    :scrollable="true"
    :position="position"
    :multiple="multiple"
    :value="value"
    :disabled="disabled"
    @input="input"
    @active-change="onActiveChanged"
    :max-height="maxHeight"
  >
    <button class="button is-select is-fullwidth px-2" :style="dropdownStyle" :class="dropdownClasses" type="button" slot="trigger">
      <div class="columns is-gapless is-vcentered is-mobile">
        <div class="column is-narrow">
          <slot name="icon"></slot>
        </div>
        <div class="column has-text-left">
          <div v-if="hasSelection" class="columns is-gapless is-vcentered is-mobile">
            <div v-for="(item, index) in selectedItems" :key="index" class="column is-narrow dropdown-text">
              <slot v-if="multiple" name="trigger" :item="item" :is-last="index === selectedItems.length - 1"></slot>
              <slot v-else name="dropdown" :item="item" :is-last="index === selectedItems.length - 1"></slot>
            </div>
          </div>
          <template v-else>
            <slot name="placeholder"></slot>
          </template>
        </div>
        <div class="column is-narrow has-arrow-icon">
          <dropdown-arrow-icon></dropdown-arrow-icon>
        </div>
      </div>
    </button>

    <b-dropdown-item v-for="(item, index) in items" :key="index" :value="index">
      <slot name="dropdown" :item="item"></slot>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import DropdownArrowIcon from "@/assets/icons/arrow2.svg";

export default {
  name: "DropdownSelect",

  components: {
    DropdownArrowIcon,
  },

  data() {
    return {
      maxHeight: "200px",
    };
  },

  props: {
    value: {},

    items: {
      type: Array,
      required: true,
    },

    multiple: {
      type: Boolean,
    },

    height: {
      type: String,
      default: null,
    },

    radius: {
      type: String,
    },

    borderRight: {
      type: Boolean,
      default: true,
    },

    borderLeft: {
      type: Boolean,
      default: true,
    },

    borderTop: {
      type: Boolean,
      default: true,
    },

    borderBottom: {
      type: Boolean,
      default: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    isFormInput: {
      type: Boolean,
      default: false,
    },

    position: {
      type: String,
      default: "is-bottom-right",
    },
  },

  methods: {
    input(selected) {
      this.$emit("input", selected);
    },

    onActiveChanged(isActive) {
      if (isActive) {
        const elementRect = this.$el.getBoundingClientRect();

        if (window.innerWidth <= 968) {
          this.maxHeight = `${Math.max(window.innerHeight - 150, 200)}px`;
        } else {
          const distanceToEnd = window.innerHeight - elementRect.bottom;
          this.maxHeight = `${distanceToEnd}px`;
        }
      }
    },
  },

  computed: {
    hasSelection() {
      return this.selectedItems.length > 0;
    },

    selectedItems() {
      const indices = [].concat(defaultArray(this.value));
      return indices.map(index => this.items[index]);
    },

    dropdownStyle() {
      let style = {
        height: this.height,
      };
      if (typeof this.radius !== "undefined") {
        style.borderRadius = this.radius;
      }
      if (typeof this.borderRight !== "undefined" && !this.borderRight) {
        style.borderRightColor = "transparent";
      }
      if (typeof this.borderLeft !== "undefined" && !this.borderLeft) {
        style.borderLeftColor = "transparent";
      }
      if (typeof this.borderBottom !== "undefined" && !this.borderBottom) {
        style.borderBottomColor = "transparent";
      }
      if (typeof this.borderTop !== "undefined" && !this.borderTop) {
        style.borderTopColor = "transparent";
      }
      return style;
    },

    dropdownClasses() {
      return {
        "is-form-input": this.isFormInput,
      };
    },
  },
};

function defaultArray(value) {
  if (typeof value === "undefined" || value === null || value < 0) {
    return [];
  }

  return value;
}
</script>

<style lang="scss" scoped>
.columns {
  overflow: hidden;
  min-width: 0;
  width: 100%;

  .column {
    min-width: 0;
  }

  .column.has-arrow-icon {
    flex: 0 0 18px !important;
    text-align: right;
  }
}

.button {
  border: 1px solid #c7c7c7 !important;
  background-color: transparent !important;
}
</style>
